<template>
  <div id="gfFrameWrapper" class="relative"></div>
</template>

<script>
import { loadScript } from '@common/dom/load-script';

export default {
  async mounted() {
    // TODO: put those in ENVs down the line
    window.gfHost = 'https://secure.sicherhelfen.org';
    window.gfSrc = 'https://secure.sicherhelfen.org/app/f8b708f3-e689-4fd1-88da-e26e69123d26?';

    await loadScript('https://secure.sicherhelfen.org/assets/js/app.min.js?v=202105');
    await loadScript('https://secure.sicherhelfen.org/assets/js/loader.min.js?v=20210>');
  }
};
</script>
