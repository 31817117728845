<template>
  <newsletter-subscribe :url="url">
    <template v-slot:form="{ subscription, subscribe, error }">
      <div class="mt-10 mb-20">
        <div class="bg-blue rounded relative">
          <div class="lg:px-24 px-8 sm:py-12 xs:py-16 py-8 flex flex-col items-center xs:block">
            <h2
              class="
              w-full lg:text-5xl md:text-4xl sm:text-3xl text-2xl text-white font-bold
              text-center xs:text-left
            "
            >
              <static-string>
                IKG Newsletter
              </static-string>
            </h2>
            <div class="block relative xs:hidden newsletter-ipad">
              <img src="../images/ipad.png" alt="iPad" />
            </div>
            <p
              class="
              w-5/6 xs:w-1/2 lg:w-full text-base text-white xs:text-opacity-80
              sm:mb-8 mb-8 -mt-10 xs:mt-4 sm:mt-0 text-center xs:text-left z-10
            "
            >
              <static-string>
                Erhalten Sie regelmäßige Updates rund <br />
                um die IKG und das jüdische Leben in Wien
              </static-string>
            </p>

            <form @submit.prevent="subscribe()">
              <div class="mb-4">
                <div
                  class="text-white text-lg mb-2"
                  :class="{ 'text-red font-bold': !subscription.lists.length }"
                >
                  <static-string>Bitte Newsletter auswählen:</static-string>
                </div>
                <label class="mt-1 flex items-center md:w-3/5">
                  <input type="checkbox" v-model="subscription.lists" value="newsletter" />
                  <span class="text-white ml-2 text-sm">
                    <static-string>Main</static-string>
                  </span>
                </label>
                <label class="mt-1 flex items-center md:w-3/5">
                  <input
                    type="checkbox"
                    v-model="subscription.lists"
                    value="rabbinate-newsletter"
                  />
                  <span class="text-white ml-2 text-sm">
                    <static-string>Rabbinat</static-string>
                  </span>
                </label>
                <label class="mt-1 flex items-center md:w-3/5">
                  <input type="checkbox" v-model="subscription.lists" value="kultur-newsletter" />
                  <span class="text-white ml-2 text-sm">
                    <static-string>Kultur</static-string>
                  </span>
                </label>
              </div>
              <div
                class="flex flex-col md:flex-row items-start xs:items-baseline w-full xs:w-3/5 lg:w-1/2 2xl:w-2/5 md:items-center"
              >
                <input
                  v-model="subscription.email"
                  type="email"
                  required
                  placeholder="Your email"
                  class="newsletter-input"
                />

                <button type="submit" class="btn-red -newsletter-bar">
                  <static-string>Anmeldung</static-string>
                </button>
              </div>
              <label class="mt-1 flex items-center md:w-3/5">
                <input type="checkbox" required name="agree" />
                <span class="text-white ml-2 text-sm color-links-properly">
                  <static-string>
                    Hiermit erkläre ich mich mit der einverstanden
                  </static-string>
                </span>
              </label>
              <span class="text-red" v-if="error">{{ error }}</span>
            </form>
          </div>
          <div class="xs:absolute xs:block hidden newsletter-ipad z-10">
            <img src="../images/ipad.png" alt="iPad" />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:thank-you>
      <div class="py-10">
        <div class="bg-blue rounded relative flex items-center justify-center py-4">
          <p class="text-xl text-center text-white">
            <static-string>
              You are now subscribed. Thank you!
            </static-string>
          </p>
        </div>
      </div>
    </template>
  </newsletter-subscribe>
</template>

<script>
export default {
  props: {
    url: { type: String, require: true }
  }
};
</script>

<style lang="scss">
.color-links-properly {
  a {
    color: inherit !important;
  }
}
</style>
