import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';
import throttle from 'lodash/throttle';

const fullConfig = resolveConfig(tailwindConfig);

export default {
  data() {
    return {
      showMenu: false,
      showSubMenu: 'gemeinde',
      currentPosition: 0,
      previousPosition: 0,
      windowWidth: window.innerWidth
    };
  },
  computed: {
    isScrollingUp() {
      return this.previousPosition > this.currentPosition;
    },

    isDetached() {
      return this.currentPosition > 200;
    },

    isHidden() {
      return this.isDetached && !this.isScrollingUp;
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('scroll', this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    onResize: throttle(function() {
      this.windowWidth = window.innerWidth;

      if (window.innerWidth >= parseInt(fullConfig.theme.screens.sm, 10) && this.showMenu) {
        this.showMenu = false;
      }
    }, 300),

    onScroll: throttle(function() {
      this.previousPosition = this.currentPosition;
      this.currentPosition = window.scrollY;
    }, 300),

    arrowClick(location) {
      if (this.showSubMenu == location) {
        return (this.showSubMenu = '');
      }
      this.showSubMenu = location;
    }
  }
};
