/**
 * Constants that refer to the current Rails environment
 */
export function env() {
  return document.querySelector('#js-wrapper').dataset.env || 'production';
}

export function isTesting() {
  return env() === 'test';
}

export function isProduction() {
  return env() === 'production';
}

export function isDevelopment() {
  return env() === 'development';
}
