<template>
  <div>
    <div v-if="!subscribed">
      <slot name="form" :subscription="subscription" :subscribe="subscribe" :error="error" />
      <VueRecaptcha
        ref="recaptcha"
        :sitekey="sitekey"
        size="invisible"
        @verify="onVerify"
        @expired="onExpired"
      />
    </div>

    <slot v-else name="thank-you" />
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import axios from '../../common/http/axios';

const getSiteKey = () => document.getElementById('js-wrapper').dataset.recaptchaSitekey;

export default {
  components: { VueRecaptcha },
  props: {
    url: { required: true, type: String },
    defaultLists: {
      required: false,
      type: Array,
      default: () => ['newsletter', 'rabbinate-newsletter', 'kultur-newsletter']
    }
  },

  data() {
    return {
      error: null,
      subscribed: false,
      sitekey: getSiteKey(),
      subscription: {
        email: '',
        lists: this.defaultLists
      },
      recaptchaResponse: null
    };
  },

  methods: {
    async subscribe() {
      if (!this.recaptchaResponse) {
        this.triggerRecaptchaChallenge();
        return;
      }

      if (this.subscribed) return;

      this.error = null;

      try {
        await axios({
          headers: {},
          url: this.url,
          method: 'POST',
          data: {
            'g-recaptcha-response-data': this.recaptchaResponse,
            subscription: { email: this.subscription.email, lists: this.subscription.lists }
          }
        });

        this.subscribed = true;
      } catch (error) {
        const {
          response: { status, data }
        } = error;

        if (status == 422 && data.errors && data.errors.includes('Invalid captcha')) {
          this.error = 'reCaptcha challenge failed. Please try again.';
          this.resetRecaptcha(); // Reset the reCaptcha so the user can try again
        } else {
          this.error = 'Something went wrong. Please try again.';
        }
      }
    },

    resetRecaptcha() {
      this.$refs.recaptcha.reset();
    },

    triggerRecaptchaChallenge() {
      this.$refs.recaptcha.execute();
    },

    onVerify(token) {
      this.recaptchaResponse = token;
      this.subscribe();
    },

    onExpired() {
      this.recaptchaResponse = null;
    }
  }
};
</script>
