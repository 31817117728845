import { isTesting } from '@common/env/env';
require('vanilla-cookieconsent');

// Check documentation at https://github.com/orestbida/cookieconsent
//
// IMPORTANT: when you include tracking script follow the following format below
// * Make the script type 'text/plain'
// * Add data-cookiecategory attribute to indicate the target category
//
// Examples:
//
// <script type="text/plain" data-cookiecategory="basic">
//    ... Basic cookies
// </script>
// <script type="text/plain" data-cookiecategory="preferences">
//    ... Preferences cookies
// </script>
export default function cookies() {
  const cookieconsent = initCookieConsent();
  cookieconsent.run({
    autorun: !isTesting(),
    page_scripts: true,
    gui_options: {
      consent_modal: {
        layout: 'cloud', // box/cloud/bar
        position: 'bottom center', // bottom/middle/top + left/right/center
        transition: 'slide' // zoom/slide
      },
      settings_modal: {
        layout: 'box', // box/bar
        // position : 'left',           // left/right
        transition: 'slide' // zoom/slide
      }
    },
    languages: window.cookiesNoticeMessages
  });
}
