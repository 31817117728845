<template>
  <static-string-injector
    :string="slotText"
    :scope="scope"
    :editable="editable"
    :preloaded-value="preloadedValue"
    v-slot="{ string }"
  >
    <component :is="tag" v-html="string"></component>
  </static-string-injector>
</template>

<script>
export default {
  props: {
    // If not passed an inherited scope will be used (if any)
    scope: { required: false, type: String },
    tag: { required: false, type: String, default: 'span' },
    editable: { required: false, type: Boolean, default: true },
    preloadedValue: { required: false, type: String }
  },

  computed: {
    slotText() {
      return this.$slots.default
        .map((node) => {
          switch (node.tag) {
            case undefined:
              return node.text.replace(/\n/g, ' ').trim(); // Text node
            case 'br':
              return '\n';
            default:
              return ''; // Forbidden VNode, do not render
          }
        })
        .join('');
    }
  }
};
</script>
