export default function timelineScroller() {
  const timelinePoints = document.querySelectorAll('.timelinePoint');
  const sizes = [
    { query: '(min-width: 640px) and (max-width: 1023px)', offset: 170 },
    { query: '', offset: 0 }
  ];

  const size = () => sizes.find(({ query }) => window.matchMedia(query).matches);
  const headerOffset = () => size().offset;

  Array.from(timelinePoints).forEach((timelinePoint) => {
    const href = timelinePoint.getAttribute('href');
    const section = document.querySelector(href);

    timelinePoint.addEventListener('click', (e) => {
      e.preventDefault();

      //get body position
      const bodyRect = document.body.getBoundingClientRect().top;
      // get section position relative
      const sectionRect = section.getBoundingClientRect().top;
      // subtract the section from body
      const sectionPosition = sectionRect - bodyRect;
      // subtract offset
      const offsetPosition = sectionPosition - headerOffset();

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    });
  });
}
