import Vue from 'vue';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import Collapsible from '../application/javascript/Collapsible';
import timelineScroller from '../application/javascript/timeline';
import Navigation from '../application/javascript/navigation';
import HasState from '../application/javascript/HasState';
import DonationEmbed from '../application/javascript/DonationEmbed';
import LoadScript from '@common/components/LoadScript.vue';
import NewsletterSubscribe from '../application/javascript/NewsletterSubscribe.vue';
import NewsletterSection from '../application/javascript/NewsletterSection.vue';
import smoothScrollTo from '../application/javascript/smooth-scroll';
import cookies from '../application/javascript/cookies';
import scroll  from '../application/javascript/scroll';

import '../application/javascript/calendar/index';
import '../application/javascript/uncrypt-mailto';
import '../admin/public/bootstrap';

import '../styles/application.scss';
import '../application/stylesheets/tailwindcss.scss';
import '../application/stylesheets/application.scss';

import AOS from 'aos';
/*! purgecss start ignore */
import 'aos/dist/aos.css';
/*! purgecss end ignore */

// Add all images from the "images" folder to the pipeline
require.context('../application/images', true);

Rails.start();
Turbolinks.start();

let vueApp;

Vue.component('load-script', LoadScript);
Vue.component('navigation', Navigation);
Vue.component('collapsible', Collapsible);
Vue.component('places-map', () => import('../application/javascript/places-map/PlacesMap'));
Vue.component('cemeteries-map', () => import('../application/javascript/places-map/CemeteriesMap'));
Vue.component('has-state', HasState);
Vue.component('donation-embed', DonationEmbed);
// Vue.component('calendar', Calendar);
Vue.component('read-more', () => import('../application/javascript/ReadMore.vue'));
Vue.component('photo-gallery', () => import('../application/javascript/PhotoGallery'));
Vue.component('swiper-slider', () => import('../application/javascript/Swiper.vue'));
Vue.component('newsletter-subscribe', NewsletterSubscribe);
Vue.component('newsletter-section', NewsletterSection);

// Bootstrap the Vue root instance
document.addEventListener('turbolinks:load', () => {
  vueApp = new Vue({ el: '#js-wrapper' });
  timelineScroller();
  smoothScrollTo();
  cookies();
  scroll();
  AOS.init();
});

document.addEventListener('turbolinks:visit', () => {
  if (vueApp) vueApp.$destroy();
});
